import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import IndexImage from "../components/index-image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Preston “wexx”, Mr. International Rubber 20 and first Asian male international fetish titleholder" />
    <section className="intro">
      <div className="intro-container">
        <h2>Hi! I'm Preston.</h2>
        <p>But you can also call me <strong>“wexx”</strong>. I'm a <strong>latex fetishist</strong>, <strong>kink educator</strong>, <strong>intersectional kink activist</strong>, <strong>event consultant</strong>, <strong>multilingual emcee</strong>, and <strong>fetish model</strong> available for hire.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <div className="content-first">
          <IndexImage />
        </div>
        <div className="content-second">
          <p><strong>Preston “wexx” So</strong> (he/they) is the first Asian male international fetish titleholder and the first Mr. International Rubber (MIR) of color. He is Mr. International Rubber 20 (2017) and Mr. New England Rubber 2016. Follow his fetish and modeling pursuits on Instagram at <a href="https://instagram.com/mirubberxx" rel="noreferrer" target="_blank">@mirubberxx</a> or <a href="https://instagram.com/tight_connection" rel="noreferrer" target="_blank">@tight_connection</a>.</p>
          <p><Link to="/about" className="call-to-action">Read more about me</Link></p>
        </div>
      </div>
    </section>
    <section className="polyptych">
      <div className="polyptych-container">
        <div className="polyptych-third">
          <h3>Event consulting</h3>
          <p>For 6+ years, I've organized both kink and professional tech events in three countries. I know what works, what doesn't, and how to run a great fetish event, including marketing, venues, programming, contests, and risk management.</p>
          <p><Link to="/contact" className="call-to-action">Contact me</Link></p>
        </div>
        <div className="polyptych-fourth">
          <h3>Risk management</h3>
          <p>A kink or fetish event isn't safe without a code of conduct, anti-harassment policy, and clear enforcement. With my 6+ years of professional event organizing experience, I can help make sure your event is safe for everyone.</p>
          <p><Link to="/contact" className="call-to-action">Contact me</Link></p>
        </div>
        <div className="polyptych-first">
          <h3>Event emceeing</h3>
          <p>With 4+ years of emceeing experience, I've hosted kink and fetish events in three languages and in four countries. Attendees compliment my dynamism, enthusiasm, humor, and ability to identify with any and all event audiences.</p>
          <p><Link to="/contact" className="call-to-action">Contact me</Link></p>
        </div>
        <div className="polyptych-third">
          <h3>Fetish modeling</h3>
          <p>For the past 4+ years, I've appeared in a range of publications modeling latex and fetish gear and have worked with some of the best-known fetish photographers in the industry. You can see some of <a href="https://twitter.com/mirubberxx" rel="noreferrer" target="_blank">my modeling work on Twitter</a>.</p>
          <p><Link to="/contact" className="call-to-action">Contact me</Link></p>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
